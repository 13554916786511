import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = ["overlay"]

  connect() {
    console.log('%c -- nikolaz.tech -- ', 'background: #004e58; color: #baead8; font-family: cursive; font-size: 20px');
  }

  toggleOverlay() {
    this.overlayTarget.classList.toggle('overlay-activated')
  }
}
