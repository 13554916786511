import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

// Connects to data-controller="flatpickr"
export default class extends Controller {
  connect() {
    new flatpickr(this.element, {
      minDate: (new Date()),
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "d/m/Y at H:i",
      time_24hr: true,
      enableTime: true
    });
  }
}
