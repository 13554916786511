import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide"
export default class extends Controller {

  static targets = ['show'];

  connect() {
  }

  showHide () {
    if(this.showTarget.style.display === 'block') {
      this.showTarget.style.display = 'none'
    } else {
      this.showTarget.style.display = 'block'
    }
  }
}
