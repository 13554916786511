import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="area-chart"
export default class extends Controller {

  static values = {
    data: Object,
    name: String,
    path: String
  }

  currentDate = new Date()
  currentData = this.dataValue
  // is an array of the days of the month potentially unsorted, so dateSorter sorts them
  dates = Object.keys(Object.values(this.currentData)[0]);
  sortedDates = this.dateSorter(this.dates);
  // data comes in objects and highCharts requires specific data structure in combination of arrays and objects
  formattedData = this.highChartDataFormatter(this.dataValue);

  connect() {
    //please check that data is displayed correctly -> dates and data correspond
    this.highChartConfig(this.formattedData);
  }

  dateSorter (dates) {
    return dates.sort((a, b) => {
        a = a.split('/');
        b = b.split('/');
        let aDate = new Date(a[0], a[1], a[2])
        let bDate = new Date(b[0], b[1], b[2])

        return aDate.getTime() - bDate.getTime()
     })
  }

  highChartDataFormatter (data) {
    const newData = Object.keys(data).map(key => {
      return ({
        name: this.humanizeText(key),
        data: this.sortedDates.map(date => data[key][date])
      })
    });
    return newData;
  }

  highChartConfig (formattedData) {
    Highcharts.setOptions({
      colors: ['#666666', '#FFF267', '#FAA74B']
    });
    Highcharts.chart('container', {
      chart: {
        type: 'area'
      },
      accessibility: {
        description: 'statistics of your services'
      },
      title: {
        text: `${this.nameValue} stats`
      },
      xAxis: {
        allowDecimals: false,
        categories: this.dates,
        labels: {
          formatter: function () {
            return this.value; // clean, unformatted number for year
          }
        },
        accessibility: {
          rangeDescription: 'Data for month'
        }
      },
      yAxis: {
        labels: {
          formatter: function () {
            return this.value;
          }
        }
      },
      tooltip: {
        pointFormat: '{series.name} <b>{point.y:,.0f}</b>'
      },
      plotOptions: {
        area: {
          pointStart: 0,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: formattedData
    });
  }

  // CONTROLS FOR CHANGING DATA TO LAST AND NEXT MONTHS
  lastMonthData () {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1)
    this.fetchDataForMonth(this.currentDate)
  }

  nextMonthData () {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1)
    this.fetchDataForMonth(this.currentDate)
  }

  fetchDataForMonth (date) {
    fetch(`${this.pathValue}.json?date=${date}`)
      .then(r => r.json())
      .then(newData => {
        this.currentData = newData.stats
        this.dates = Object.keys(Object.values(this.currentData)[0])
        this.sortedDates = this.dateSorter(this.dates);
        this.formattedData = this.highChartDataFormatter(this.currentData);
        this.highChartConfig(this.formattedData);
      })
      .catch(e => console.log(e))
  }

  // Utils
  humanizeText (text) {
    let newText = (text[0].toUpperCase() + text.slice(1)).replaceAll('_', ' ')
    return newText
  }
}
