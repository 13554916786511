import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chartrace"
export default class extends Controller {
  static values = {
    data: Object,
    name: String
  }

  connect() {
    const formattedData = this.highChartDataFormater(this.dataValue);
    this.highChartConfig(formattedData);
  }

  highChartDataFormater (obj) {
    return Object.keys(obj).map(key => {
      return {name: this.humanizeText(key), data: [obj[key]]}
    }).sort((a, b) => b.data[0] - a.data[0])
  }

  humanizeText (text) {
    let newText = (text[0].toUpperCase() + text.slice(1)).replaceAll('_', ' ')
    return newText
  }

  highChartConfig (dataInput) {
    Highcharts.setOptions({
      colors: ['#FFF267', '#666666', '#FAA74B']
    });

    Highcharts.chart(`container-${this.nameValue}`, {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: [`${this.nameValue}`],
        visible: false
      },
      yAxis: {
        // labels: {
        //   enabled: false
        // },
        // visible: false,
        // // reversed: true,
        // min: 0,
        // title: {
        //   text: null
        // }
      },
      tooltip: {
        pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.0f}%</b><br/>',
        shared: true
      },
      plotOptions: {
        bar: {
          stacking: 'overlap',
          // dataLabels: {
          //   enabled: false,
          //   format: '{series.name}: {y}%',
          //   color: 'black',
          //   rotation: -55,
          //   y: -100,
          //   x: 30
          // }
        }
      },
      series: dataInput
    });
  }
}
