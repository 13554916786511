import Sortable from 'stimulus-sortable'
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="sortable"
export default class extends Sortable {

  static values = {
    updateUrl: String
  }

  connect() {
    super.connect()
    this.sortable
    this.options
    this.defaultOptions
  }

  // You can override the `onUpdate` method here.
    onUpdate(event) {
    super.onUpdate(event)
    let positionsArray = Array.from(event.to.children).map(i => i.id)
    this.sendPositions(positionsArray)
  }

  async sendPositions(positions) {
    const request = new FetchRequest(
      'post',
      this.updateUrlValue,
      {body: {positions: positions}}
    )
    const response = await request.perform()
    if(response.ok) {
      console.log('positions updated');
    } else {
      console.warn(`server responded with : ${response.status}`);
    }
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions() {
    return {
      animation: 150,
    }
  }
}
