import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="on-change-fetch"
export default class extends Controller {
  static targets = ['changeable']
  static values = {
    url: String
  }

  initialize() {
    this.formData = new FormData(this.changeableTarget);
  }

  connect() { }

  handleFetch(e) {
    e.preventDefault();
    this.formData.set(e.target.name, e.target.value)
    this.sendForm();
  }

  async sendForm() {
    const request = new FetchRequest(
      'post',
      this.urlValue,
      {
        body: {
          service_web_link: this.selectFields()
        },
        responseKind: "turbo-stream"
      }
    )
    const response = await request.perform()
    if (response.ok) {
      console.log('preview fetched');
    } else {
      console.warn('server responded with an error');
    }
  }

  selectFields() {
    const fields = Object.fromEntries(this.formData);
    return {
      name: fields['service_web_link[name]'],
      description: fields['service_web_link[description]'],
      href_url: fields['service_web_link[href_url]'],
      inner_text: fields['service_web_link[inner_text]'],
      use_css: fields['service_web_link[use_css]'],
      color: fields['service_web_link[color]'],
      background_color: fields['service_web_link[background_color]'],
      font_family: fields['service_web_link[font_family]'],
      font_style: fields['service_web_link[font_style]'],
      font_size: fields['service_web_link[font_size]'],
      padding: fields['service_web_link[padding]'],
      border_width: fields['service_web_link[border_width]'],
      border_style: fields['service_web_link[border_style]'],
      border_color: fields['service_web_link[border_color]'],
      border_radius: fields['service_web_link[border_radius]'],
      box_shadow_x: fields['service_web_link[box_shadow_x]'],
      box_shadow_y: fields['service_web_link[box_shadow_y]'],
      box_shadow_blur: fields['service_web_link[box_shadow_blur]'],
      box_shadow_color: fields['service_web_link[box_shadow_color]'],
      box_shadow_x_hover: fields['service_web_link[box_shadow_x_hover]'],
      box_shadow_y_hover: fields['service_web_link[box_shadow_y_hover]'],
      box_shadow_blur_hover: fields['service_web_link[box_shadow_blur_hover]'],
      box_shadow_color_hover: fields['service_web_link[box_shadow_color_hover]'],
    };
  }
}
