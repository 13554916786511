import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="confirm-delete"
export default class extends Controller {
  connect() {
  }

  askConfirmation (e) {
    let response =  confirm('Are you sure you want to delete this service?');
    if(!response) e.preventDefault();
  }
}
