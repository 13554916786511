import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="query-target-promorecipients"
export default class extends Controller {
  static targets = [
    'recipientsTargeted', 'targetMinScore', 'targetMaxScore',
    'targetFromDate', 'targetToDate'
  ];

  static values = {
    servicePublicId: String
  };

  connect() {
    const options = this.#optionsInput();
    this.fetchRecipients(options)
  }

  updateRecipients() {
    const options = this.#optionsInput();
    this.fetchRecipients(options);
  }

  #optionsInput() {
    return {
      date_from: this.targetFromDateTarget.value,
      date_to: this.targetToDateTarget.value,
      min_score: this.targetMinScoreTarget.value,
      max_score: this.targetMaxScoreTarget.value
    }
  };

  fetchRecipients(options) {
    const recipientUrl = `/dashboard/services/${this.servicePublicIdValue}/promo_recipients.json`;
    const paramsUrl = `?min_score=${options.min_score}&max_score=${options.max_score}&from=${options.date_from}&to=${options.date_to}`;
    fetch(`${recipientUrl}${paramsUrl}`)
      .then(response => response.json())
      .then(data => {
        this.recipientsTargetedTarget.innerText = data.potential_recipients.length;
      })
      .catch(error => console.log(error))
  };
}
