import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-form-validator"
export default class extends Controller {
  static targets = ['message', 'email', 'submitBtn'];

  connect() {
  }

  checkContactValidity() {
    this.submitBtnTarget.disabled = true;
    const email = this.emailTarget.value
    const message = this.messageTarget.value;
    if(this.regexVerify(email) && message) {
      this.submitBtnTarget.disabled = false;
    }
  }

  regexVerify(email) {
    // const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/i
    const reg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return email.match(reg)
  }
}
