import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="query-target-emailcampaignrecipients"
export default class extends Controller {
  static targets = ['recipientsTargeted', 'targetScore', 'targetFrom'];

  static values = {
    servicePublicId: String
  };

  connect() {
    const options = this.optionsInput();
    this.fetchRecipients(options)
  };

  updateRecipients() {
    const options = this.optionsInput();
    this.fetchRecipients(options);
  };

  optionsInput() {
    return {
      date: this.targetFromTargets.map(i => i.value).join('/'),
      score: this.targetScoreTarget.value
    }
  };

  fetchRecipients(options) {
    const recipientUrl = `/dashboard/services/${this.servicePublicIdValue}/email_campaign_recipients.json`;
    const paramsUrl = `?score=${options.score}&from=${options.date}`;
    fetch(`${recipientUrl}${paramsUrl}`)
      .then(response => response.json())
      .then(data => {
        this.recipientsTargetedTarget.innerText = data.potential_recipients.length;
      })
      .catch(error => console.log(error))
  };
}
