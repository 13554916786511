import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['copied']
  static values = {
    copyable: String
  }
  connect() {
  }

  copyToClipboard(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.copyableValue);
    this.copiedTarget.style.display = 'inline-block'
  }
}
